import bg_image from "../../../assets/pictures/background/image-4.jpg"
import logo from "../../../assets/images/logos/logo_white.png"
import {Paths} from "../../App";

export default function Footer() {
    return (
        <>
            {/*Main Footer*/}
            <footer className="main-footer">
                {/*background-image:url(images/background/image-4.jpg)*/}

                {/*Footer Upper*/}
                <div className="footer-upper" style={{backgroundImage: `url(${bg_image})`}}>
                    <div className="auto-container">
                        <div className="row clearfix">

                            {/*Two 4th column*/}
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="row clearfix">
                                    {/*Footer Column*/}
                                    <div className="col-lg-6 col-sm-6 col-xs-12 column">
                                        <div className="footer-widget about-widget">
                                            <div className="text">
                                                <p>
                                                    Specialists in affordable water and sanitation solutions for low-income communities and distributors of
                                                    water
                                                    quality testing and treatment equipment/supplies
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-sm-6 col-xs-12 column">
                                        <div className="footer-widget about-widget">
                                            <div className="logo">
                                                <img src={logo} alt="" style={{width: '75%', height: 'auto'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Two 4th column End*/}

                            {/*Two 4th column*/}
                            <div className="col-md-6 col-sm-12 col-xs-12">
                                <div className="row clearfix">
                                    {/*Footer Column*/}
                                    <div className="col-lg-6 col-sm-6 col-xs-12 column">
                                        <div className="footer-widget links-widget">
                                            <h3>quick Links</h3>
                                            <ul>
                                                <li><a href={Paths.route_about}>About AGCL</a></li>
                                                <li><a href={Paths.route_products}>Products</a></li>
                                                <li><a href={Paths.route_services}>Services</a></li>
                                                <li className={'d-none'}><a href={Paths.route_blogs}>Blogs</a></li>
                                                <li><a href={Paths.route_contact}>Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/*Footer Column*/}
                                    <div className="col-lg-6 col-sm-6 col-xs-12 column">
                                        <div className="footer-widget contact-widget">
                                            <h3>Address</h3>
                                            <ul className="contact-info">
                                                <li>
                                                    <strong>Head Office:</strong> <br/>
                                                    AAA Complex, House No. 3, Plot No. 214<br/>
                                                    Block No. 4241 Kisaasi, Namatovu Close
                                                </li>
                                                <li>
                                                    <strong>Phone</strong>
                                                    <br/>+256 772 625650<br/>+256 704 681860
                                                </li>
                                            </ul>

                                            <div className="social-links">
                                                <a href="#">
                                                    <span className="fa fa-facebook-f"></span>
                                                </a>
                                                <a href="#">
                                                    <span className="fa fa-twitter"></span>
                                                </a>
                                                <a href="#">
                                                    <span className="fa fa-google-plus"></span>
                                                </a>
                                                <a href="#">
                                                    <span className="fa fa-skype"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Two 4th column End*/}
                        </div>
                    </div>
                </div>

                {/*Footer Bottom*/}
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="pull-left">
                                <div className="copyright-text">&copy; Copyright 2023 Abudiwe Group, All Rights Reserved</div>
                            </div>
                            <div className="pull-right">

                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
