import {PropsWithChildren} from "react";
import TopBar from "./TopBar";
import AppBar from "./AppBar";
import Footer from "./Footer";

export default function Container(params: PropsWithChildren<{}>) {
    return (
        <>
            <div className="page-wrapper">
                {/*Preloader*/}
               {/* <div className="preloader"></div>*/}

                {/*Main Header / Style One*/}
                <header className="main-header header-style-two">
                    <TopBar/>

                    {/*Header-Upper*/}
                    <AppBar/>

                    {/*Header-Lower*/}
                    <div className="header-lower">
                        <div className="auto-container"/>
                    </div>
                </header>
                {/*End Main Header*/}

                {params.children}

                <Footer/>
            </div>
            {/*End pagewrapper*/}

            {/*Scroll to top*/}
            <div className="scroll-to-top scroll-to-target" data-target=".main-header">
                <span className="icon flaticon-tool-3"></span>
            </div>
        </>
    )
}