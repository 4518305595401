import Container from "../../utils/nav/Container";
import bg from "../../../assets/images/background/bg-page-title.jpg"
import {Paths} from "../../App";

import img_1 from "../../../assets/images/resource/blog-image-5.jpg"
import img_2 from "../../../assets/images/resource/blog-image-6.jpg"
import img_3 from "../../../assets/images/resource/blog-image-7.jpg"
import img_4 from "../../../assets/images/resource/blog-image-8.jpg"
import img_5 from "../../../assets/images/resource/blog-image-9.jpg"
import img_6 from "../../../assets/images/resource/blog-image-10.jpg"

import img_7 from "../../../assets/images/resource/post-thumb-3.jpg"
import img_8 from "../../../assets/images/resource/post-thumb-4.jpg"

export default function Blogs() {
    return (
        <>
            <Container>

                {/*Page Title*/}
                <section className="page-title" style={{backgroundImage: "url(" + bg + ")"}}>
                    <div className="auto-container">
                        <h1>our latest news</h1>
                        <div className="text">
                            Claritas est etiam processus dynamicus, qui sequitur <br/>mutationem consuetudium lectorum.
                        </div>
                    </div>
                </section>

                {/*Breadcrumb*/}
                <div className="theme-breadcrumb">
                    <div className="auto-container">
                        <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                            <li><a href={Paths.route_home}>Home</a></li>
                            <li className="active">Latest News</li>
                        </ol>
                    </div>
                </div>


                {/*Sidebar Page Container*/}
                <div className="sidebar-page-container">
                    <div className="auto-container">
                        <div className="row clearfix">

                            {/*Content Side*/}
                            <div className="content-side col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div className="blog-posts-container two-column">
                                    <div className="row clearfix">

                                        {/*Default Blog Post*/}
                                        <article className="default-blog-post col-lg-6 col-sm-6 col-xs-12">
                                            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                <div className="inner-most">
                                                    <figure className="image-box"><a href="#"><img src={img_1} alt=""/></a></figure>
                                                    <div className="lower-part">
                                                        <div className="left-curve"></div>
                                                        <div className="right-curve"></div>
                                                        <div className="date-box">
                                                            <span className="day">15</span>
                                                            <br/>Jan
                                                        </div>

                                                        <div className="content">
                                                            <h3><a href="#">Blog title goes here</a></h3>
                                                            <div className="author-info"><a href="#">by farhan rizvi</a></div>
                                                            <div className="text">
                                                                Ut enim ad minima veniam, quis nostrum nisi ut aliquid etx
                                                                ea commodi consequatur voluptate velit esse quam...
                                                            </div>
                                                            <div className="more-link">
                                                                <a href={`${Paths.route_blogs}/1`} className="read-more theme-btn">continue
                                                                    reading <span className="icon icon-right fa fa-long-arrow-right"></span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                        {/*Default Blog Post*/}
                                        <article className="default-blog-post col-lg-6 col-sm-6 col-xs-12">
                                            <div className="inner-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                <div className="inner-most">
                                                    <figure className="image-box"><a href="#"><img src={img_2} alt=""/></a></figure>
                                                    <div className="lower-part">
                                                        <div className="left-curve"></div>
                                                        <div className="right-curve"></div>
                                                        <div className="date-box">
                                                            <span className="day">15</span>
                                                            <br/>Jan
                                                        </div>

                                                        <div className="content">
                                                            <h3><a href="#">Blog title goes here</a></h3>
                                                            <div className="author-info"><a href="#">by farhan rizvi</a></div>
                                                            <div className="text">
                                                                Ut enim ad minima veniam, quis nostrum nisi ut aliquid etx
                                                                ea commodi consequatur voluptate velit esse quam...
                                                            </div>
                                                            <div className="more-link">
                                                                <a href={`${Paths.route_blogs}/1`} className="read-more theme-btn">continue
                                                                    reading <span className="icon icon-right fa fa-long-arrow-right"></span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                        {/*Default Blog Post*/}
                                        <article className="default-blog-post col-lg-6 col-sm-6 col-xs-12">
                                            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                <div className="inner-most">
                                                    <figure className="image-box"><a href="#"><img src={img_3} alt=""/></a></figure>
                                                    <div className="lower-part">
                                                        <div className="left-curve"></div>
                                                        <div className="right-curve"></div>
                                                        <div className="date-box">
                                                            <span className="day">15</span>
                                                            <br/>Jan
                                                        </div>

                                                        <div className="content">
                                                            <h3><a href="#">Blog title goes here</a></h3>
                                                            <div className="author-info"><a href="#">by farhan rizvi</a></div>
                                                            <div className="text">
                                                                Ut enim ad minima veniam, quis nostrum nisi ut aliquid etx
                                                                ea commodi consequatur voluptate velit esse quam...
                                                            </div>
                                                            <div className="more-link">
                                                                <a href={`${Paths.route_blogs}/1`} className="read-more theme-btn">continue
                                                                    reading <span className="icon icon-right fa fa-long-arrow-right"></span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                        {/*Default Blog Post*/}
                                        <article className="default-blog-post col-lg-6 col-sm-6 col-xs-12">
                                            <div className="inner-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                <div className="inner-most">
                                                    <figure className="image-box"><a href="#"><img src={img_4} alt=""/></a></figure>
                                                    <div className="lower-part">
                                                        <div className="left-curve"></div>
                                                        <div className="right-curve"></div>
                                                        <div className="date-box">
                                                            <span className="day">15</span>
                                                            <br/>Jan
                                                        </div>

                                                        <div className="content">
                                                            <h3><a href="#">Blog title goes here</a></h3>
                                                            <div className="author-info"><a href="#">by farhan rizvi</a></div>
                                                            <div className="text">Ut enim ad minima veniam, quis nostrum nisi ut aliquid etx
                                                                ea commodi consequatur voluptate velit esse quam...
                                                            </div>
                                                            <div className="more-link">
                                                                <a href={`${Paths.route_blogs}/1`} className="read-more theme-btn">continue
                                                                    reading <span className="icon icon-right fa fa-long-arrow-right"></span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                        {/*Default Blog Post*/}
                                        <article className="default-blog-post col-lg-6 col-sm-6 col-xs-12">
                                            <div className="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                                <div className="inner-most">
                                                    <figure className="image-box"><a href="#"><img src={img_5} alt=""/></a></figure>
                                                    <div className="lower-part">
                                                        <div className="left-curve"></div>
                                                        <div className="right-curve"></div>
                                                        <div className="date-box">
                                                            <span className="day">15</span>
                                                            <br/>Jan
                                                        </div>

                                                        <div className="content">
                                                            <h3><a href="#">Blog title goes here</a></h3>
                                                            <div className="author-info"><a href="#">by farhan rizvi</a></div>
                                                            <div className="text">Ut enim ad minima veniam, quis nostrum nisi ut aliquid etx
                                                                ea commodi consequatur voluptate velit esse quam...
                                                            </div>
                                                            <div className="more-link"><a href="#" className="read-more theme-btn">continue
                                                                reading <span className="icon icon-right fa fa-long-arrow-right"></span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                        {/*Default Blog Post*/}
                                        <article className="default-blog-post col-lg-6 col-sm-6 col-xs-12">
                                            <div className="inner-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                <div className="inner-most">
                                                    <figure className="image-box"><a href="#"><img src={img_6} alt=""/></a></figure>
                                                    <div className="lower-part">
                                                        <div className="left-curve"></div>
                                                        <div className="right-curve"></div>
                                                        <div className="date-box">
                                                            <span className="day">15</span>
                                                            <br/>Jan
                                                        </div>
                                                        <div className="content">
                                                            <h3><a href="#">Blog title goes here</a></h3>
                                                            <div className="author-info"><a href="#">by farhan rizvi</a></div>
                                                            <div className="text">Ut enim ad minima veniam, quis nostrum nisi ut aliquid etx
                                                                ea commodi consequatur voluptate velit esse quam...
                                                            </div>
                                                            <div className="more-link"><a href="#" className="read-more theme-btn">continue
                                                                reading <span className="icon icon-right fa fa-long-arrow-right"></span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                    </div>


                                    {/* Styled Pagination */}
                                    <div className="styled-pagination text-left wow bounceInLeft" data-wow-delay="0ms"
                                         data-wow-duration="1500ms">
                                        <ul>
                                            <li><a className="prev" href="#">
                                                <span className="fa fa-angle-double-left"></span>
                                            </a></li>
                                            <li><a href="#">1</a></li>
                                            <li><a href="#" className="active">2</a></li>
                                            <li><a href="#">3</a></li>
                                            <li><a className="next" href="#">
                                                <span className="fa fa-angle-double-right"></span>
                                            </a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            {/*Sidebar*/}
                            <div className="sidebar-outer col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <aside className="sidebar">

                                    {/*Search Widget*/}
                                    <div className="sidebar-widget search-widget">
                                        <div className="widget-inner">
                                            <form method="post" action="https://html.themexriver.com/constructor-one/index.html">
                                                <div className="form-group">
                                                    <input type="search" name="search" value="" placeholder="Search News Here"/>
                                                    <button type="submit" className="theme-btn">
                                                        <span className="fa fa-search"></span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {/*News Widget*/}
                                    <div className="sidebar-widget news-widget">
                                        <div className="sidebar-title"><h3>Recent News</h3></div>
                                        <div className="widget-inner">
                                            {/*News Item*/}
                                            <div className="news-item">
                                                <figure className="post-thumb"><a href="#"><img src={img_7} alt=""/></a></figure>
                                                <h4><a href="#">Duis autem vel eum iriure dolor</a></h4>
                                                <div className="time">21st feb, 2016</div>
                                            </div>

                                            {/*News Item*/}
                                            <div className="news-item">
                                                <figure className="post-thumb"><a href="#"><img src={img_8} alt=""/></a></figure>
                                                <h4><a href="#">Duis autem vel eum iriure dolor</a></h4>
                                                <div className="time">21st feb, 2016</div>
                                            </div>

                                            {/*News Item*/}
                                            <div className="news-item">
                                                <figure className="post-thumb"><a href="#"><img src={img_7} alt=""/></a></figure>
                                                <h4><a href="#">Duis autem vel eum iriure dolor</a></h4>
                                                <div className="time">21st feb, 2016</div>
                                            </div>

                                            {/*News Item*/}
                                            <div className="news-item">
                                                <figure className="post-thumb"><a href="#"><img src={img_8} alt=""/></a></figure>
                                                <h4><a href="#">Duis autem vel eum iriure dolor</a></h4>
                                                <div className="time">21st feb, 2016</div>
                                            </div>

                                        </div>
                                    </div>

                                    {/*Links Widget*/}
                                    <div className="sidebar-widget links-widget">
                                        <div className="sidebar-title"><h3>Category</h3></div>
                                        <div className="widget-inner">
                                            <ul>
                                                <li><a href="#"> Architect</a></li>
                                                <li><a href="#"> Building</a></li>
                                                <li><a href="#"> Travel</a></li>
                                                <li><a href="#"> Culture</a></li>
                                                <li><a href="#"> School</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}
