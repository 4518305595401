import React from "react";

export interface Slider {
    name: string
    caption: string
    url: string
    img: string
}

export default function HomeSlider(params: { sliders: Slider[] }) {
    const [sliderIndex, setSliderIndex] = React.useState(0);
    const changeSliderIndex = (newIndex: number) => {
        let slides = document.getElementsByClassName("slider") as HTMLCollectionOf<HTMLDivElement>;
        if (slides.length > 0) {
            if (newIndex > slides.length - 1) {
                setSliderIndex(0)
            } else if (newIndex < 0) {
                setSliderIndex(0)
            } else {
                setSliderIndex(newIndex)
            }
        }
    }

    React.useEffect(() => {
        changeSlide()

        const test = window.setInterval(() => {
            changeSliderIndex(sliderIndex + 1)
        }, 5000)

        return () => clearInterval(test);
    }, [sliderIndex])

    function changeSlide() {
        let slides = document.getElementsByClassName("slider") as HTMLCollectionOf<HTMLDivElement>;
        let dots = document.getElementsByClassName("dot") as HTMLCollectionOf<HTMLDivElement>;

        for (let index = 0; index < slides.length; index++) {
            slides[index].style.display = "none";
        }

        for (let index = 0; index < dots.length; index++) {
            dots[index].className = dots[index].className.replace(" active", "");
        }

        slides[sliderIndex].style.display = "block";
        dots[sliderIndex].className += " active";

        slides[sliderIndex].addEventListener("mouseenter", function () {
            //timer.pause()
        });

        slides[sliderIndex].addEventListener("mouseleave", function () {
            //timer.play()
        });
    }

    return (
        <>
            {/*Main Slider*/}
            <section className="main-slider style-two">
                <div className={'slider_container'}>
                    {
                        params.sliders.map((slider, index) =>
                            <div key={index} className='slider'>
                                <div className="number_text">{index + 1} / {params.sliders.length}</div>
                                <div style={{backgroundImage: `url(${slider.img})`}} className={'bg_image'}/>

                                <div className="text">
                                    <div className='caption'>
                                        {slider.name}
                                        <br/>
                                        <span>{slider.caption}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }


                    {/*Next and previous buttons*/}
                    <div className="prev" onClick={() => changeSliderIndex(sliderIndex - 1)}>&#10094;</div>
                    <div className="next" onClick={() => changeSliderIndex(sliderIndex + 1)}>&#10095;</div>

                    <br/>
                    {/*The dots/circles*/}
                    <div style={{textAlign: 'center'}}>
                        {
                            params.sliders.map((slider, index) =>
                                <span key={index} className="dot" onClick={() => changeSliderIndex(index)}></span>
                            )
                        }
                    </div>
                </div>
            </section>
        </>
    )
}
