import Container from "../utils/nav/Container";
import {Paths} from "../App";

import image_1 from "../../assets/images/background/bg-page-title.jpg"
import image_2 from "../../assets/images/resource/default-image-3.jpg"

export default function NotFound() {
    return (
        <Container>
            {/*Page Title*/}
            <section className="page-title" style={{backgroundImage: `url(${image_1})`}}>
                <div className="auto-container">
                    <h1>404 Error</h1>
                    <div className="text">
                    </div>
                </div>
            </section>

            {/*Breadcrumb*/}
            <div className="theme-breadcrumb">
                <div className="auto-container">
                    <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                        <li><a href={Paths.route_home}>Home</a></li>
                        <li className="active">404</li>
                    </ol>
                </div>
            </div>


            {/*Error Section*/}
            <section className="error-section">
                <div className="auto-container">
                    <div className="row clearfix">
                        {/*Image Column*/}
                       {/* <div className="image-column col-md-6 col-sm-12 col-xs-12">
                            <figure><img src={image_2} alt=""/></figure>
                        </div>*/}
                        {/*Text Column*/}
                        <div className="text-column col-md-6 col-sm-12 col-xs-12">
                            <h2>404 error!</h2>
                            <h4>the page you are looking for is not found! please check the link again.</h4>
                            <div className="text-left">
                                <a href={Paths.route_home} className="theme-btn btn-style-two">
                                    <span className="icon icon-left flaticon-arrows-21"></span> back to home
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Container>
    )
}
