import {Paths} from "../../App";

export default function TopBar() {
    return (
        <>
            {/* Header Top*/}
            <div className="header-top">
                <div className="auto-container clearfix">
                    {/*Top Left*/}
                    <div className="top-left">
                        <ul className="clearfix">
                            <li>
                                <span className="icon flaticon-letter-1" style={{fontSize: '12px'}}>
                                    info@AbudiweGroup.com
                                </span>
                            </li>
                            <li>
                                <span className="icon flaticon-technology-2" style={{fontSize: '12px'}}>
                                    +256 772 625650, +256 704 681860
                                </span>
                            </li>
                        </ul>
                    </div>

                    {/*Top Right*/}
                    <div className="top-right">
                        <ul className="clearfix">
                            <li><a href={Paths.route_home} style={{fontSize: '14px'}}>Abudiwe Group of Companies Limited</a></li>
                        </ul>
                    </div>

                </div>
            </div>
            {/*Header Top End*/}

        </>
    )
}
