import React from "react";

import Container from "../../utils/nav/Container";
import {Paths} from "../../App";
import {useParams} from 'react-router-dom';
import {Requests} from "../../utils/Requests";
import {Utils} from "../../utils/Utils";

import bg from "../../../assets/images/background/bg-page-title.jpg"
import img_2 from "../../../assets/images/background/image-1.jpg"

interface Note {
    info_desc: string[]
    info_id: number
    info_notes: string[]
    info_title: string
}

interface Item {
    product_cost: number
    product_desc: string[]
    product_id: number
    product_image: string
    product_name: string
    notes: Note[]
    images: string[]
}

interface Other {
    product_id: number
    product_image: string
    product_name: string
}

export default function Product() {
    let {product_id} = useParams();
    const preloader = React.useRef<HTMLDivElement | null>(null)

    const [others, setOthers] = React.useState(Array<Other>())
    const [item, setItem] = React.useState<Item>({
        notes: [], product_cost: 0, product_desc: [], product_id: 0, product_image: "", product_name: "", images: []
    })

    function resize_grid_item(item: HTMLDivElement) {
        const grid = document.getElementsByClassName("masonry_grid")[0];
        const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
        const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
        const rowSpan = Math.ceil((item.querySelector('.grid_content')!.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
        item.style.gridRowEnd = "span " + rowSpan;
    }

    function resize_grid_items() {
        const allItems = document.getElementsByClassName("grid_item");
        for (let index = 0; index < allItems.length; index++) {
            resize_grid_item(allItems[index] as HTMLDivElement);
        }
    }

    const get_product = () => {
        if (product_id === undefined) {
            //window.location.replace("404")
        } else {
            Requests.get_product({product_id: parseInt(product_id.trim())})
                .then((response) => {
                    if (response.data.hasOwnProperty("item")) {
                        Utils.hide_preloader(preloader.current as HTMLDivElement)
                        setItem(response.data.item)
                        setOthers(response.data.others)
                    } else {
                        alert("Could not load item, please reload page again")
                    }
                })
                .catch(() => {
                    alert("Could not load item, please reload page again")
                })
        }
    }

    React.useEffect(() => {
        if (item.product_id > 0) {
            resize_grid_items()
        }
    }, [item])

    React.useEffect(() => {
        get_product()
    }, [])

    return (
        <>
            <Container>
                {/*Preloader*/}
                <div className="preloader" ref={preloader}/>

                {/*Page Title*/}
                <section className="page-title" style={{backgroundImage: `url(${bg})`}}>
                    <div className="auto-container">
                        <h1>{item.product_name}</h1>
                    </div>
                </section>

                {/*Breadcrumb*/}
                <div className="theme-breadcrumb">
                    <div className="auto-container">
                        <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                            <li><a href={Paths.route_home}>Home</a></li>
                            <li><a href={Paths.route_products}>Products</a></li>
                            <li className="active">{item.product_name}</li>
                        </ol>
                    </div>
                </div>


                {/*Single Project*/}
                <section className="single-project no-padd-top" style={{background: "#F2F2F2"}}>
                    <div className="auto-container">

                        {/*Upper Main Part*/}
                        <div className="upper-part">
                            {/*Upper Image Section*/}
                            <div className="upper-box">
                                <figure className="main-image" style={{marginRight: '0'}}>
                                    <img src={`${Requests.BASE_URL}${item.product_image}`} alt=""/>
                                </figure>
                            </div>

                            <div className="lower-box">
                                <div className="row clearfix">
                                    <div className="col-md-8 col-sm-12 col-xs-12">
                                        <div className="related-thumbs clearfix">
                                            {
                                                item.images.map((image, index) =>
                                                    <figure className="thumb wow fadeInUp" data-wow-delay="0ms"
                                                            data-wow-duration="1500ms" key={index}>
                                                        <a
                                                            href={`${Requests.BASE_URL}${image}`} className="lightbox-image"
                                                            title="Image Caption Here">
                                                            <img src={`${Requests.BASE_URL}${image}`} alt=""/>
                                                        </a>
                                                    </figure>
                                                )
                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-12 col-xs-12" style={{display: 'none'}}>
                                        <div className="text-right padd-top-30 wow fadeInRight" data-wow-delay="1000ms"
                                             data-wow-duration="1500ms">
                                            <a href="#" className="theme-btn btn-style-two">Download Brochure <span
                                                className="icon icon-right fa fa-download"></span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row clearfix">
                            <div className='content-side col-md-8'>
                                <div className="text-column">
                                    <h3>Description:</h3>
                                    <div className="text">
                                        {item.product_desc.map((desc, index) => <p key={index}>{desc}</p>)}
                                    </div>
                                </div>
                            </div>

                            <div className="sidebar-outer col-lg-4 col-md-4  col-xs-12">
                                <aside className="sidebar">
                                    {/*News Widget*/}
                                    <div className="sidebar-widget news-widget">
                                        <div className="sidebar-title"><h3>Other Products</h3></div>
                                        <div className="widget-inner">
                                            {
                                                others.map((other) =>
                                                    <div className="news-item" key={other.product_id}>
                                                        <figure className="post-thumb">
                                                            <img src={`${Requests.BASE_URL}${other.product_image}`} alt=""/>
                                                        </figure>
                                                        <h4>
                                                            <a href={`/product/${other.product_id}`}>
                                                                {other.product_name}
                                                            </a>
                                                        </h4>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </aside>
                            </div>

                        </div>

                        <div className='masonry_grid'>
                            {
                                item.notes.map((note, index) =>
                                    <div className="grid_item" key={index}>
                                        <div className="grid_content text-column">
                                            <h3 style={{fontSize: '14px'}}>{note.info_title}:</h3>

                                            {note.info_desc.map((bDesc, index) => <p key={index}>{bDesc}</p>)}

                                            <ul>
                                                {
                                                    note.info_notes.map((aInfo, aIndex) =>
                                                        <li key={aIndex}>{aInfo}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>


                {/*Default Intro Section*/}
                <section className="default-intro-section" style={{backgroundImage: `url(${img_2})`}}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-md-10 col-sm-12 col-xs-12 pull-right">
                                {/*Content Box*/}
                                <div className="content-box">
                                    <div className="row clearfix">
                                        <div className="col-md-7 col-sm-12">
                                            <h3>Get your free consultant</h3>
                                            <div className="text">
                                                We are a company that offers design and build services for you from
                                                initial sketches to the final construction.
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 pull-right">
                                            <div className="text-right padd-top-20">
                                                <a href="#" className="theme-btn btn-style-three">
                                                    Contact us <span className="icon icon-right flaticon-arrows-10"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}
