import axios from "axios";
import {Utils} from "./Utils";

export class Requests {
    static BASE_URL = "https://api.abudiwegroup.com//"

    static get_products() {
        return axios({method: 'get', url: 'agcl/products/list'});
    }

    static get_product(params: { product_id: number }) {
        return axios({method: 'get', url: 'agcl/products/product', params: params});
    }

    static save_product(data: {}) {
        return axios({method: 'post', url: 'agcl/products/save/product', data: Utils.post_data(data)});
    }

    static save_product_info(data: {}) {
        return axios({method: 'post', url: 'agcl/products/save/info', data: Utils.post_data(data)});
    }

    static send_email(data: {name: string, email: string, contact: string, subject: string, body: string}){
        return axios({method: 'post', url: 'agcl/email', data: Utils.post_data(data)});
    }
}
