import Container from "../../utils/nav/Container";
import HomeSlider, {Slider} from "./HomeSlider";

import bg_1 from "../../../assets/images/background/image-5.jpg"
import bg_2 from "../../../assets/images/background/image-1.jpg"

import gal_1 from "../../../assets/images/gallery/5.jpg"
import gal_2 from "../../../assets/images/gallery/6.jpg"
import gal_3 from "../../../assets/images/gallery/7.jpg"
import gal_4 from "../../../assets/images/gallery/8.jpg"
import gal_5 from "../../../assets/images/gallery/9.jpg"

import post_1 from "../../../assets/images/resource/man-image-1.png"
import post_2 from "../../../assets/images/resource/post-thumb-1.jpg"
import post_3 from "../../../assets/images/resource/post-thumb-2.jpg"
import {our_services} from "../services/Services";
import {Requests} from "../../utils/Requests";
import {Utils} from "../../utils/Utils";
import React from "react";
import {Product} from "../products/Products";

export default function Home() {
    const [sliders, setSliders] = React.useState(Array<Slider>())
    const preloader = React.useRef<HTMLDivElement | null>(null)
    const load_products = () => {
        Requests.get_products()
            .then((response) => {
                if (response.data.hasOwnProperty("products")) {
                    Utils.hide_preloader(preloader.current as HTMLDivElement);
                    let aSliders: Slider[] = [];
                    (response.data.products as Product[]).forEach((aProduct) => {
                        aSliders = [...aSliders, {
                            name: aProduct.product_name,
                            caption: aProduct.product_desc[0],
                            url: `/product/${aProduct.product_id}`,
                            img: `${Requests.BASE_URL}${aProduct.product_image}`
                        }]
                    })

                    setSliders(aSliders)
                }
            })
    }

    React.useEffect(() => {
        load_products()
    }, [])

    return (
        <>
            <Container>
                <div className="preloader" ref={preloader}/>

                {sliders.length > 0 && <HomeSlider sliders={sliders}/>}

                {/*Fun Facts Section*/}
                <section className="fun-facts d-none" style={{backgroundImage: `url(${bg_1})`}}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-md-10 col-sm-12 col-xs-12 pull-right">
                                {/*Content Box*/}
                                <div className="fact-counter content-box">
                                    <div className="row clearfix">

                                        {/*Counter Column*/}
                                        <article className="col-lg-3 col-sm-6 col-xs-12 counter-column wow fadeIn" data-wow-duration="0ms">
                                            <div className="icon-box"><span className="icon flaticon-business-3"></span></div>
                                            <div className="count-outer">
                                                <span className="count-text" data-speed="1500" data-stop="241">0</span> Projects
                                            </div>
                                        </article>

                                        {/*Counter Column*/}
                                        <article className="col-lg-3 col-sm-6 col-xs-12 counter-column wow fadeIn" data-wow-duration="0ms">
                                            <div className="icon-box"><span className="icon flaticon-social-1"></span></div>
                                            <div className="count-outer">
                                                <span className="count-text" data-speed="1500" data-stop="73">0</span> Clients
                                            </div>
                                        </article>

                                        {/*Counter Column*/}
                                        <article className="col-lg-3 col-sm-6 col-xs-12 counter-column wow fadeIn" data-wow-duration="0ms">
                                            <div className="icon-box"><span className="icon flaticon-food-8"></span></div>
                                            <div className="count-outer">
                                                <span className="count-text" data-speed="1500" data-stop="98">0</span>% Success
                                            </div>
                                        </article>


                                        {/*Counter Column*/}
                                        <article className="col-lg-3 col-sm-6 col-xs-12 counter-column wow fadeIn" data-wow-duration="0ms">
                                            <div className="icon-box"><span className="icon flaticon-cup"></span></div>
                                            <div className="count-outer">
                                                <span className="count-text" data-speed="1500" data-stop="32">0</span> Awards
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Services Section*/}
                <section className="services-style-one">
                    <div className="auto-container">
                        {/*Section Title*/}
                        <div className="sec-title text-center">
                            <h2>Our <span className="theme_color">Services</span></h2>
                            <div className="text">

                            </div>
                        </div>

                        <div className="row clearfix">

                            {/*Default Service Column*/}
                            {
                                our_services.map((aService) =>
                                    <div className="default-service-column col-md-4 col-sm-6 col-xs-12" key={aService.service_id}>
                                        <div className="inner-box wow zoomInStable" data-wow-delay="0ms" data-wow-duration="1500ms">
                                            <div className="inner-most">
                                                <figure className="image-box">
                                                    <a href={`/service/${aService.service_id}`}>
                                                        <img src={aService.images[0].image} alt="" style={{height: '350px'}}/>
                                                    </a>
                                                </figure>
                                                <div className="lower-part">
                                                    <div className="left-curve"></div>
                                                    <div className="right-curve"></div>

                                                    <div className="content">
                                                        <h3>{aService.service_name}</h3>
                                                        <div className="more-link">
                                                            <a href={`/service/${aService.service_id}`} className="read-more">Read more</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </div>
                </section>

                {/*Latest Work*/}
                <section className="gallery-section latest-work d-none">
                    <div className="auto-container">
                        {/*Section Title*/}
                        <div className="sec-title text-center">
                            <h2>Latest <span className="theme_color">Works</span></h2>
                            <div className="text">

                            </div>
                        </div>

                        {/*Sortable Masonry*/}
                        <div className="sortable-masonry mixed-gallery-section">
                            {/*Filter*/}
                            <div className="filters text-center">
                                <ul className="filter-tabs filter-btns clearfix anim-3-all">
                                    <li className="active filter" data-role="button" data-filter=".all"><span className="txt">All</span>
                                    </li>
                                    <li className="filter" data-role="button" data-filter=".building"><span className="txt">Building</span>
                                    </li>
                                    <li className="filter" data-role="button" data-filter=".wooding"><span className="txt">Wooding</span>
                                    </li>
                                    <li className="filter" data-role="button" data-filter=".school"><span className="txt">School</span></li>
                                    <li className="filter" data-role="button" data-filter=".other"><span className="txt">Other</span></li>
                                </ul>
                            </div>

                            <div className="items-container row clearfix">
                                {/*Column*/}
                                <div className="column big-block all school">
                                    {/*Default Portfolio Item*/}
                                    <div className="default-portfolio-item">
                                        <div className="inner-box">
                                            <figure className="image-box"><img src={gal_1} alt=""/></figure>
                                            <a href={gal_1} className="lightbox-image overlay-link" title="Image Caption Here">
                                                <span className="icon flaticon-cross"></span>
                                            </a>
                                            <div className="caption-layer">
                                                <div className="upper">
                                                    <h3><a href="src/app/pages#">wood making</a></h3>
                                                    <div className="category"><a href="src/app/pages#">Pre Work</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*Column*/}
                                <div className="column small-block all school building other">
                                    {/*Default Portfolio Item*/}
                                    <div className="default-portfolio-item">
                                        <div className="inner-box">
                                            <figure className="image-box"><img src={gal_2} alt=""/></figure>
                                            <a href={gal_2} className="lightbox-image overlay-link" title="Image Caption Here">
                                                <span className="icon flaticon-cross"></span>
                                            </a>
                                            <div className="caption-layer">
                                                <div className="upper">
                                                    <h3><a href="src/app/pages#">wood making</a></h3>
                                                    <div className="category"><a href="src/app/pages#">Pre Work</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*Column*/}
                                <div className="column small-block all wooding">
                                    {/*Default Portfolio Item*/}
                                    <div className="default-portfolio-item">
                                        <div className="inner-box">
                                            <figure className="image-box"><img src={gal_3} alt=""/></figure>
                                            <a href={gal_3} className="lightbox-image overlay-link" title="Image Caption Here">
                                                <span className="icon flaticon-cross"></span>
                                            </a>
                                            <div className="caption-layer">
                                                <div className="upper">
                                                    <h3><a href="src/app/pages#">Roofing works</a></h3>
                                                    <div className="category"><a href="src/app/pages#">Pre Work</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*Column*/}
                                <div className="column small-block all school other">
                                    {/*Default Portfolio Item*/}
                                    <div className="default-portfolio-item">
                                        <div className="inner-box">
                                            <figure className="image-box"><img src={gal_4} alt=""/></figure>
                                            <a href={gal_4} className="lightbox-image overlay-link" title="Image Caption Here">
                                                <span className="icon flaticon-cross"></span>
                                            </a>
                                            <div className="caption-layer">
                                                <div className="upper">
                                                    <h3><a href="src/app/pages#">wood making</a></h3>
                                                    <div className="category"><a href="src/app/pages#">Pre Work</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*Column*/}
                                <div className="column small-block all wooding building other">
                                    {/*Default Portfolio Item*/}
                                    <div className="default-portfolio-item">
                                        <div className="inner-box">
                                            <figure className="image-box"><img src={gal_5} alt=""/></figure>
                                            <a href={gal_5} className="lightbox-image overlay-link" title="Image Caption Here"><span
                                                className="icon flaticon-cross"></span></a>
                                            <div className="caption-layer">
                                                <div className="upper">
                                                    <h3><a href="src/app/pages#">Roofing works</a></h3>
                                                    <div className="category"><a href="src/app/pages#">Pre Work</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/*Sortable Masonry*/}
                    </div>

                    <div className="text-center padd-top-50"><a href="src/app/pages#" className="more-link">See more works</a></div>
                </section>

                {/*Default Intro Section*/}
                <section className="default-intro-section d-none" style={{backgroundImage: `url(${bg_2})`}}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-md-10 col-sm-12 col-xs-12 pull-right">
                                {/*Content Box*/}
                                <div className="content-box">
                                    <div className="row clearfix">
                                        <div className="col-md-7 col-sm-12">
                                            <h3>Get your free consultant</h3>
                                            <div className="text">We are a company that offers design and build services for you from
                                                initial sketches to the final construction.
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 pull-right">
                                            <div className="text-right padd-top-20 wow fadeInRight" data-wow-delay="0ms"
                                                 data-wow-duration="1500ms"><a href="src/app/pages#" className="theme-btn btn-style-three">Contact
                                                us <span className="icon icon-right flaticon-arrows-10"></span></a></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                {/*Latest News*/}
                <section className="latest-news d-none">
                    <div className="auto-container">
                        <div className="outer-box">

                            {/*Left Image*/}
                            <div className="left-image wow bounceInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <img src={post_1} alt=""/>
                            </div>

                            {/*Section Title*/}
                            <div className="sec-title text-left">
                                <h2>Latest <span className="theme_color">News</span></h2>
                                <div className="text">

                                </div>
                            </div>

                            <div className="row clearfix">
                                {/* Post Item*/}
                                <div className="post-item col-md-6 col-sm-6 col-xs-12">
                                    <div className="inner-box clearfix wow fadeInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                                        <figure className="post-thumb">
                                            <img src={post_2} alt=""/>
                                            {/*Curve Box*/}
                                            <div className="curve-box">
                                                <div className="left-curve"></div>
                                                <div className="right-curve"></div>
                                            </div>
                                            {/*Curve Box*/}
                                        </figure>
                                        <div className="text-content">
                                            <h4><a href="src/app/pages#">Claritas est etiam processus dynamicus</a></h4>
                                            <div className="time">23rd sep, 2015</div>
                                        </div>
                                    </div>
                                </div>

                                {/* Post Item*/}
                                <div className="post-item col-md-6 col-sm-6 col-xs-12">
                                    <div className="inner-box clearfix wow fadeInRight" data-wow-delay="0ms" data-wow-duration="1500ms">
                                        <figure className="post-thumb">
                                            <img src={post_3} alt=""/>
                                            {/*Curve Box*/}
                                            <div className="curve-box">
                                                <div className="left-curve"></div>
                                                <div className="right-curve"></div>
                                            </div>
                                            {/*Curve Box*/}
                                        </figure>
                                        <div className="text-content">
                                            <h4><a href="src/app/pages#">Claritas est etiam processus dynamicus</a></h4>
                                            <div className="time">23rd sep, 2015</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}
