import Container from "../../utils/nav/Container";

import {Paths} from "../../App";
import React from "react";
import {Requests} from "../../utils/Requests";
import {Utils} from "../../utils/Utils";

import bg from "../../../assets/images/background/bg-page-title.jpg"
import bg_2 from "../../../assets/images/background/image-1.jpg"

export interface Product {
    product_cost: number
    product_desc: string[]
    product_id: number
    product_image: string
    product_name: string
}

export default function Products() {
    const preloader = React.useRef<HTMLDivElement | null>(null)
    const [products, setProducts] = React.useState(Array<Product>())
    const load_products = () => {
        Requests.get_products()
            .then((response) => {
                if (response.data.hasOwnProperty("products")) {
                    Utils.hide_preloader(preloader.current as HTMLDivElement)
                    setProducts(response.data.products)
                } else {
                    alert("Could not load data, please reload page again")
                }
            })
            .catch((error) => {
                console.error(error)
                alert("Could not load data, please reload page again")
            })
    }

    React.useEffect(() => {
        load_products()
    }, [])

    return (
        <>
            <Container>
                {/*Preloader*/}
                <div className="preloader" ref={preloader}/>

                {/*Page Title*/}
                <section className="page-title" style={{backgroundImage: `url(${bg})`}}>
                    <div className="auto-container">
                        <h1>Our Products</h1>
                        <div className="text">

                        </div>
                    </div>
                </section>

                {/*Breadcrumb*/}
                <div className="theme-breadcrumb">
                    <div className="auto-container">
                        <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                            <li><a href={Paths.route_home}>Home</a></li>
                            <li className="active">Our Products</li>
                        </ol>
                    </div>
                </div>

                {/*Projects Section*/}
                <section className="services-style-one no-padd-top">
                    <div className="auto-container">

                        <div className="row clearfix">

                            {/*Default Service Column*/}
                            {
                                products.map((product) =>
                                    <div className="default-service-column col-md-4 col-sm-6 col-xs-12" key={product.product_id}>
                                        <div className="inner-box">
                                            <div className="inner-most">
                                                <figure className="image-box">
                                                    <a href={`product/${product.product_id}`}>
                                                        <img src={`${Requests.BASE_URL}${product.product_image}`} alt=""/>
                                                    </a>
                                                </figure>
                                                <div className="lower-part">
                                                    <div className="left-curve"></div>
                                                    <div className="right-curve"></div>

                                                    <div className="content">
                                                        <h3>{product.product_name}</h3>
                                                        <div className="text">
                                                            {product.product_desc[0]}
                                                        </div>
                                                        <div className="more-link">
                                                            <a href={`product/${product.product_id}`} className="read-more">
                                                                Read more
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>

                {/*Default Intro Section*/}
                <section className="default-intro-section" style={{backgroundImage: `url(${bg_2})`}}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-md-10 col-sm-12 col-xs-12 pull-right">
                                {/*Content Box*/}
                                <div className="content-box">
                                    <div className="row clearfix">
                                        <div className="col-md-7 col-sm-12">
                                            <h3>Get your free consultant</h3>
                                            <div className="text">We are a company that offers design and build services for you from
                                                initial sketches to the final construction.
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 pull-right">
                                            <div className="text-right padd-top-20">
                                                <a href={Paths.route_contact} className="theme-btn btn-style-three">
                                                    Contact us <span className="icon icon-right flaticon-arrows-10"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}
