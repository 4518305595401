import Container from "../../utils/nav/Container";
import React from "react";
import {useParams} from "react-router-dom";
import {our_services, OurService} from "./Services";
import {Requests} from "../../utils/Requests";
import {Utils} from "../../utils/Utils";
import bg from "../../../assets/images/background/bg-page-title.jpg";
import {Paths} from "../../App";

export default function Service() {
    let {service_id} = useParams();
    const preloader = React.useRef<HTMLDivElement | null>(null)

    const [serrvice, setService] = React.useState<OurService>({
        images: [], service_desc: [], service_id: 0, service_name: ""
    })

    const get_service = () => {
        if (service_id === undefined) {
            window.location.replace("404")
        } else {
            const aService = our_services.filter((aS) => aS.service_id.toString() === service_id)
            Utils.hide_preloader(preloader.current as HTMLDivElement)
            if (aService.length === 1) {
                setService(aService[0])
            } else {
                alert("Could not load item, please reload page again")
                window.location.replace("404")
            }
        }
    }

    React.useEffect(() => {
        get_service()
    }, [])

    return (
        <>
            <Container>
                {/*Preloader*/}
                <div className="preloader" ref={preloader}/>

                {/*Page Title*/}
                <section className="page-title" style={{backgroundImage: `url(${bg})`}}>
                    <div className="auto-container">
                        <h1>{serrvice.service_name}</h1>
                    </div>
                </section>

                {/*Breadcrumb*/}
                <div className="theme-breadcrumb">
                    <div className="auto-container">
                        <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                            <li><a href={Paths.route_home}>Home</a></li>
                            <li><a href={Paths.route_services}>Services</a></li>
                            <li className="active">{serrvice.service_name}</li>
                        </ol>
                    </div>
                </div>

                <section className="single-project no-padd-top" style={{background: "#F2F2F2"}}>
                    <div className="auto-container">

                        <div className="row clearfix">
                            <div className='content-side col-md-8'>
                                <div className="text-column">
                                    <h3>Description:</h3>
                                    <div className="text">
                                        {serrvice.service_desc.map((desc, index) => <p key={index}>{desc}</p>)}
                                    </div>

                                    <div className="row clearfix">
                                        {
                                            serrvice.images.map((image, index)=>
                                                <div className="col-lg-6 col-md-6 col-xs-12 service_image" key={index}>
                                                    <img src={image.image} alt={image.caption}/>
                                                    <div className='caption'>{image.caption}</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="sidebar-outer col-lg-4 col-md-4  col-xs-12">
                                <aside className="sidebar">
                                    {/*News Widget*/}
                                    <div className="sidebar-widget news-widget">
                                        <div className="sidebar-title"><h3>Other Services</h3></div>
                                        <div className="widget-inner">
                                            {
                                                our_services
                                                    .filter((aS) => aS.service_id.toString() !== service_id)
                                                    .map((other) =>
                                                        <div className="news-item" key={other.service_id}>
                                                            <figure className="post-thumb">
                                                                <img src={other.images[0].image} alt="" style={{height: '55px'}}/>
                                                            </figure>
                                                            <h4>
                                                                <a href={`/service/${other.service_id}`}>
                                                                    {other.service_name}
                                                                </a>
                                                            </h4>
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </aside>
                            </div>

                        </div>

                    </div>
                </section>

            </Container>
        </>
    )
}