import Container from "../utils/nav/Container";

import {Paths} from "../App";

import bg_1 from "../../assets/images/background/bg-page-title.jpg"
import bg_2 from "../../assets/images/background/image-1.jpg"

import img_1 from "../../assets/pictures/services/6.jpg"

import team_1 from "../../assets/pictures/team/team_1.jpg"
import team_2 from "../../assets/pictures/team/team_2.jpg"

export default function About() {
    return (
        <>
            <Container>
                {/*Page Title*/}
                <section className="page-title" style={{backgroundImage: `url(${bg_1})`}}>
                    <div className="auto-container">
                        <h1>About Us</h1>
                        <div className="text">

                        </div>
                    </div>
                </section>

                {/*Breadcrumb*/}
                <div className="theme-breadcrumb">
                    <div className="auto-container">
                        <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                            <li><a href={Paths.route_home}>Home</a></li>
                            <li className="active">About Us</li>
                        </ol>
                    </div>
                </div>


                {/*Default Two Column*/}
                <section className="default-two-column padd-bott-50">
                    <div className="auto-container">
                        <div className="row clearfix">
                            {/*Image Column*/}
                            <div className="column image-column col-md-6 col-sm-12 col-xs-12">
                                <img src={img_1} alt="" style={{height: '350px', width: '100%'}}/>
                            </div>

                            {/*Text-Column*/}
                            <div className="column text-column col-md-6 col-sm-12 col-xs-12">
                                <div className="text padd-left-20 padd-top-50" style={{color: '#000000'}}>
                                    <p>
                                        Abudiwe group of Companies Limited is limited liability company incorporated in 2019 in Uganda.
                                    </p>
                                    <p>
                                        The Company was established by Mr. Richard Data and his wife Jolly Aliya Milly to achieve their
                                        childhood dreams of creating solutions for low-income communities, conserving the environment for
                                        future generations, and supporting the most vulnerable in the community.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*Services Section*/}
                <section className="services-section">
                    <div className="outer-box">
                        <div className="row clearfix">
                            {/*Service Column*/}
                            <div className="column service-column col-xs-12">
                                <h3>Who we are</h3>
                                <div className="text">
                                    Specialists in affordable water and sanitation solutions for low-income communities and distributors of water
                                    quality testing and treatment equipment/supplies
                                </div>
                            </div>
                            {/*Service Column*/}
                            <div className="column service-column col-xs-12">
                                <h3>Our Mission</h3>
                                <div className="text">
                                    Create a built environment that can sustain the current and future needs of low-income communities.
                                </div>
                            </div>
                            {/*Service Column*/}
                            <div className="column service-column col-xs-12">
                                <h3>Our Values</h3>
                                <div className="text">
                                    <ul>
                                        <li><b>Integrity:</b> We will always adhere to high moral standards</li>
                                        <li><b>Service:</b> We are committed to provide timely and quality service to our clients.</li>
                                        <li><b>Accountability:</b> We are accountable to our clients, partners, and employees</li>
                                        <li>
                                            <b>Diversity and Inclusion:</b> We will address the needs of all in the society with particular attention to
                                            marginalized groups and create an inclusive working environment.
                                        </li>
                                        <li><b>Respect:</b> We will respect the values and interests of our clients, partners, and employees.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/*Team Section*/}
                <section className="team-section bg-light no-bg">
                    <div className="auto-container">
                        {/*Section Title*/}
                        <div className="sec-title text-center">
                            <h2>AGCL <span className="theme_color">Team</span></h2>
                        </div>

                        <div className="row clearfix">
                            {/*Team Member*/}
                            <article className="team-member col-md-6 col-xs-12">
                                <div className="inner-box" style={{color: '#000000'}}>
                                    <figure className="image-box wow bounceInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                                        <img src={team_1} alt=""/>
                                    </figure>

                                    <h3 style={{textAlign:'center'}}>Richard Data, MSc, BSc<br/>(Co-Founder and CEO)</h3>
                                    <div className="category">
                                        Richard Data was groomed by his late father John Cafenol Ayile from childhood to become an Engineer and he
                                        has been a Water Sanitation and Hygiene (WASH) specialist in both the Humanitarian Sector and the private
                                        sector for over 20 years. He has worked with some of the Known International Humanitarian Organizations such
                                        as International Rescue Committee, Oxfam Great Britain, Concern Worldwide and CARE International. He has
                                        worked in low-income communities in Africa and middle east and understands the challenges facing the WASH
                                        Sector. He is also a director and shareholder of Bazoki Company Limited based in Arua. Richard Holds a
                                        Diploma in Water Engineering from Kyambogo University, a Bachelor of Science in Civil and Structural
                                        Engineering from Engineering Institute of Technology-Australia and a Master of Science in Water and Waste
                                        Engineering from Loughborough University-UK.
                                    </div>
                                </div>
                            </article>

                            {/*Team Member*/}
                            <article className="team-member col-md-6 col-xs-12">
                                <div className="inner-box" style={{color: '#000000'}}>
                                    <figure className="image-box wow bounceInUp" data-wow-delay="500ms" data-wow-duration="1500ms">
                                        <img src={team_2} alt=""/>
                                    </figure>

                                    <h3 style={{textAlign:'center'}}>
                                        Jolly M. Aliya Data, BBA, PGD<br/>(Co-Founder and General Manager)
                                    </h3>
                                    <div className="category">
                                        Jolly has a strong Christian background with the passion of giving back to the less privileged. She was raised by her
                                        parents who are professionals in Education and passionate about educating future generations. She has experience in
                                        establishing businesses and making them succeed in various contexts. She is also a director and shareholder in God wins
                                        infant and primary School and Psalms vocational institute in Mukono. Jolly holds a Bachelor of Business Administration
                                        from Uganda Christian University (UCU) in Uganda and Post Graduate Diploma in Financial Management from Uganda
                                        Management Institute (UMI) in Uganda.
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </section>

                {/*Default Intro Section*/}
                <section className="default-intro-section" style={{backgroundImage: `url(${bg_2})`}}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-md-10 col-sm-12 col-xs-12 pull-right">
                                {/*Content Box*/}
                                <div className="content-box">
                                    <div className="row clearfix">
                                        <div className="col-md-7 col-sm-12">
                                            <h3>Get your free consultant</h3>
                                            <div className="text">
                                                We are a company that offers design and build services for you from
                                                initial sketches to the final construction.
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 pull-right">
                                            <div className="text-right padd-top-20">
                                                <a href={Paths.route_contact} className="theme-btn btn-style-three">
                                                    Contact us <span className="icon icon-right flaticon-arrows-10"></span>
                                                </a></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}
