import React from 'react';
import {Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import Contact from "./pages/Contact";
import Blogs from "./pages/blogs/Blogs";
import Blog from "./pages/blogs/Blog";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Products from "./pages/products/Products";
import Product from "./pages/products/Product";
import Services from "./pages/services/Services";
import Service from "./pages/services/Service";
import axios from "axios";
import {Requests} from "./utils/Requests";

export default function App() {
    const set_axios_data = () => {
        axios.defaults.baseURL = Requests.BASE_URL
        axios.defaults.headers['common']['Content-Type'] = 'application/json'
        axios.defaults.timeout = 1000 * 60

        axios.interceptors.response.use(function (response) {
            console.log(response.data)
            return response
        }, function (error) {
            console.log(error)
            return Promise.reject(error);
        })
    }
    set_axios_data()

    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>

                <Route path={Paths.route_about} element={<About/>}/>

                <Route path={Paths.route_services} element={<Services/>}></Route>
                <Route path={`service/:service_id`} element={<Service/>}/>

                <Route path={Paths.route_products} element={<Products/>}></Route>
                <Route path={`product/:product_id`} element={<Product/>}/>

                <Route path={Paths.route_blogs} element={<Blogs/>}/>
                <Route path={`${Paths.route_blogs}/:blog_id`} element={<Blog/>}/>

                <Route path={Paths.route_contact} element={<Contact/>}/>

                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    );
}

export class Paths {
    static route_home = "/"
    static route_about = "/about"
    static route_products = "/products"
    static route_services = "/services"
    static route_blogs = "/blogs"
    static route_contact = "/contact"
}
