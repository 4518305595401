import Container from "../utils/nav/Container";
import bg from "../../assets/images/background/bg-page-title.jpg"
import {Paths} from "../App";
import React, {ChangeEvent} from "react";
import {Utils} from "../utils/Utils";
import {Requests} from "../utils/Requests";

declare var google: any
declare var jQuery: any

interface Message {
    name: string
    email: string
    contact: string
    subject: string
    body: string
}

export default function Contact() {
    const preloader = React.useRef<HTMLDivElement | null>(null)
    const mapCanvas = React.useRef<HTMLDivElement | null>(null)
    const init_map = () => {
        let lat = 0.3671998428099009,
            lng = 32.59724596819455,
            zoom = 12,
            scrollwheel = false,
            zoomcontrol = true,
            draggable = true,
            mapType = google.maps.MapTypeId.ROADMAP,
            title = 'Abudiwe Group',
            contentString = "AAA Complex, House No.3, Plot No.214, Block No.4241 Kisaasi, Namatovu Close<br><a href='mailto:info@youremail.com'>info@AbudiweGroup.com</a>";


        if (navigator.userAgent.match(/iPad|iPhone|Android/i)) {
            draggable = false;
        }

        const mapOptions = {
            zoom: zoom,
            scrollwheel: scrollwheel,
            zoomControl: zoomcontrol,
            draggable: draggable,
            center: new google.maps.LatLng(lat, lng),
            mapTypeId: mapType
        };
        const map = new google.maps.Map(mapCanvas.current as HTMLDivElement, mapOptions);

        const image = 'images/icons/map-marker.png';

        contentString = `<div class="map-data"><h6>${title}</h6><div class="map-content">${contentString}</div></div>`
        const infoWindow = new google.maps.InfoWindow({content: contentString})
        const marker = new google.maps.Marker({
            position: new google.maps.LatLng(lat, lng), map: map, icon: image, title: title
        });

        google.maps.event.addListener(marker, 'click', function () {
            infoWindow.open(map, marker);
        });

        const styles = [
            {"featureType": "administrative", "elementType": "labels.text.fill", "stylers": [{"color": "#444444"}]},
            {"featureType": "landscape", "elementType": "all", "stylers": [{"color": "#ECECEC"}]},
            {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"}]},
            {"featureType": "road", "elementType": "all", "stylers": [{"saturation": -100}, {"lightness": 45}]},
            {"featureType": "road.highway", "elementType": "all", "stylers": [{"visibility": "simplified"}]},
            {"featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{"visibility": "off"}]},
            {"featureType": "transit", "elementType": "all", "stylers": [{"visibility": "off"}]},
            {"featureType": "water", "elementType": "all", "stylers": [{"color": "#202E31"}, {"visibility": "on"}]}
        ];
        map.setOptions({styles: styles});
    }

    const [message, setMessage] = React.useState<Message>({body: "", contact: "", email: "", name: "", subject: ""})
    const handle_change = (name: string, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setMessage({...message, [name]: event.target.value})
    }
    const send_email = () => {
        const invalid_email = (email: string): boolean => {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return !regex.test(email);
        }

        const invalid_name = (name: string): boolean => {
            const regex = /^[a-zA-Z ]{2,100}$/;
            return !regex.test(name);
        }

        const invalid_contact = (contact: string): boolean => {
            return contact.replace(/\D/g, '').length < 10
        }

        if (invalid_name(message.name)) {
            window.alert("Enter a valid name")
        } else if (invalid_email(message.email)) {
            window.alert("Enter a valid email address")
        } else if (invalid_contact(message.contact)) {
            window.alert("Enter a valid contact")
        } else if (message.subject.trim().length < 5) {
            window.alert("Enter a valid message subject")
        } else if (message.body.trim().length < 25) {
            window.alert("Enter an exhaustive message content")
        } else {
            const loader = preloader.current as HTMLDivElement
            loader.style.display = "block"
            loader.style.opacity = "1"

            Requests.send_email({
                name: message.name.trim(), email: message.email.trim(), subject: message.subject.trim(), body: message.body.trim(),
                contact: message.contact.replace(/\D/g, ''),
            })
                .then((response) => {
                    console.log(response)


                    Utils.hide_preloader(preloader.current as HTMLDivElement)
                    if (response.data.hasOwnProperty("code") && response.data.code === 1) {
                        alert(response.data.status === 1 ? "Message send successfully" : "Could not send message. Please try again")
                        if (response.data.status === 1) {
                            setMessage({body: "", contact: "", email: "", name: "", subject: ""})
                        }
                    } else {
                        alert("Error sending message, please retry")
                    }
                })
                .catch((error) => {
                    console.error(error)
                    alert("Error sending message, please retry")
                })
        }
    }

    React.useEffect(() => {
        Utils.hide_preloader(preloader.current as HTMLDivElement)
        init_map()
    }, [])

    return (
        <>
            <Container>
                {/*Preloader*/}
                <div className="preloader" ref={preloader}/>

                {/*Page Title*/}
                <section className="page-title" style={{backgroundImage: "url(" + bg + ")"}}>
                    <div className="auto-container">
                        <h1>Contact us</h1>
                        <div className="text">

                        </div>
                    </div>
                </section>

                {/*Breadcrumb*/}
                <div className="theme-breadcrumb">
                    <div className="auto-container">
                        <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                            <li><a href={Paths.route_home}>Home</a></li>
                            <li className="active">Contact us</li>
                        </ol>
                    </div>
                </div>


                {/*Contact Section*/}
                <section className="contact-section">
                    {/*Map Container*/}
                    <div className="map-container">
                        {/*Map Canvas*/}
                        <div className="map-canvas" style={{height: '100%'}} ref={mapCanvas}></div>
                    </div>

                    <div className="auto-container">
                        {/*Comment Form*/}
                        <div className="contact-form-outer">
                            <div className="sidebar-title">
                                <h3>Send Us a Message</h3>
                            </div>

                            <div className="inner-box">
                                <form>
                                    <div className="row clearfix">
                                        <div className="form-group col-lg-6 col-md-6 col-xs-12">
                                            <input type="text" name="name" value={message.name} placeholder="YOUR NAME"
                                                   onChange={(event) => handle_change('name', event)}/>
                                        </div>

                                        <div className="form-group col-lg-6 col-md-6 col-xs-12">
                                            <input type="email" name="email" value={message.email} placeholder="EMAIL ADDRESS"
                                                   onChange={(event) => handle_change('email', event)}/>
                                        </div>

                                        <div className="form-group col-lg-6 col-md-6 col-xs-12">
                                            <input type="text" name="contact" value={message.contact} placeholder="PHONE NUMBER" required
                                                   onChange={(event) => handle_change('contact', event)}/>
                                        </div>

                                        <div className="form-group col-lg-6 col-md-6 col-xs-12">
                                            <input type="text" name="subject" value={message.subject} placeholder="SUBJECT"
                                                   onChange={(event) => handle_change('subject', event)}/>
                                        </div>

                                        <div className="form-group col-lg-12 col-sm-12 col-xs-12">
                                            <textarea value={message.body} name="body" placeholder="MESSAGE"
                                                      onChange={(event) => handle_change('body', event)}></textarea>
                                        </div>

                                    </div>
                                    <div className="text-left">
                                        <button type="button" className="theme-btn btn-style-two" onClick={send_email}>
                                            Send Now <span className="icon icon-right flaticon-arrows-10"></span>
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className="contact-info clearfix">
                                {/*Info Box*/}
                                <div className="column info-box">
                                    <div className="icon-box">
                                        <span className="flaticon-location"></span>
                                    </div>
                                    <ul>
                                        <li>
                                            <span className="small-title">Find us</span>
                                        </li>
                                        <li><a href="#"><strong>
                                            AAA Complex, House No.3, Plot No.214, Block No.4241 Kisaasi, Namatovu Close
                                        </strong></a></li>
                                    </ul>
                                </div>

                                {/*Info Box*/}
                                <div className="column info-box">
                                    <div className="icon-box">
                                        <span className="flaticon-technology"></span>
                                    </div>
                                    <ul>
                                        <li>
                                            <span className="small-title">Call us today</span>
                                        </li>
                                        <li><a href="#"><strong>+256 772 625650 or +256 704 681860</strong></a></li>
                                    </ul>
                                </div>

                                <div className="column info-box">
                                    <div className="icon-box">
                                        <span className="flaticon-note-1"></span>
                                    </div>
                                    <ul>
                                        <li>
                                            <span className="small-title">Write to us</span>
                                        </li>
                                        <li><a href="#"><strong>P.O. Box 20085, Kampala, Uganda</strong></a></li>
                                    </ul>
                                </div>

                                {/*Info Box*/}
                                <div className="column info-box">
                                    <div className="icon-box">
                                        <span className="flaticon-note"></span>
                                    </div>
                                    <ul>
                                        <li>
                                            <span className="small-title">Email us now</span>
                                        </li>
                                        <li><a href="#"><strong>info@AbudiweGroup.com</strong></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}
