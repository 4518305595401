import Container from "../../utils/nav/Container";
import {Paths} from "../../App";

import bg from "../../../assets/images/background/bg-page-title.jpg"
import img from "../../../assets/images/background/image-1.jpg"

import ser_1 from "../../../assets/pictures/services/1.jpg"
import ser_2 from "../../../assets/pictures/services/2.png"
import ser_3 from "../../../assets/pictures/services/3.jpg"
import ser_4 from "../../../assets/pictures/services/4.jpg"
import ser_5 from "../../../assets/pictures/services/5.png"
import ser_6 from "../../../assets/pictures/services/6.jpg"
import ser_7 from "../../../assets/pictures/services/7.jpg"
import ser_8 from "../../../assets/pictures/services/8.jpg"
import ser_9 from "../../../assets/pictures/services/9.png"
import ser_10 from "../../../assets/pictures/services/license.jpg"

export interface OurService {
    service_id: number
    service_name: string
    service_desc: string[],
    images: { image: string, caption: string }[]
}

export const our_services: OurService[] = [
    {
        service_id: 1, service_name: 'Water Treatment',
        service_desc: [
            "We distribute water treatment chemical such as aluminum sulphate and HTH chlorine for water treatment. " +
            "Additionally, we distribute water treatment equipment for small-large scale water treatment equipment such " +
            "chlorine dosing equipment, UV lamps and Sediment filters. We also do distribute Point of Use water treatment " +
            "chemicals and equipment for households such as Aquatabs and ceramic filters."
        ],
        images: [
            {image: ser_1, caption: "Ceramic filter made by SPOUTS of Water"},
            {image: ser_2, caption: "Aquatabs flo made by Aquatabs"},
            {image: ser_3, caption: "A Chlorine dowsing made by DOSATRON"}
        ]
    },
    {
        service_id: 2, service_name: 'Water quality monitoring',
        service_desc: ["We distribute water testing equipment and consumable for assessing physical, bacteriological, and chemical properties of water. We also have professional trainers who train those who purchase and use our equipment as part of our after-sale service with discounted training fees. Currently we are an authorized distributor of DelAgua Water Testing Limited in Uganda."],
        images: [{image: ser_4, caption: 'Display of stock in office'}, {image: ser_10, caption: "Water quality monitoring license"}]
    },
    {
        service_id: 3, service_name: 'Shallow well construction',
        service_desc: ["AGCL has piloted the use of 1.2m diameter concrete rings to line shallow wells to maximize the use of the shallow aquifers. The design provides safety during the sinking of the well, increases recharge rate by including perforations in the concrete rings and provides enough overnight storage for wells with low recharge rates. Below are photos of a shallow well-constructed in Magere for one of our private clients."],
        images: [
            {image: ser_5, caption: "Perforated Concrete ring for Shallow well construction"},
            {image: ser_6, caption: "Shallow well head"},
            {image: ser_7, caption: "Casting of Shallow well head"},
            {image: ser_8, caption: "Completed shallow well awaiting installation of pumping system"},
        ]
    },
    {
        service_id: 4, service_name: 'Construction of rainwater harvesting system',
        service_desc: ["AGCL has a team of Water resource engineers who design and construct rainwater harvesting systems that are affordable, sustainable and meets clients water needs. Our engineers match the rainfall intensity to the water demand and design storage facilities that can store water cumulatively to cover both the wet and dry seasons of the year."],
        images: [
            {image: ser_9, caption: "Underground rainwater harvesting tank under construction"},
        ]
    }
]

export default function Services() {
    return (
        <>
            <Container>
                <section className="page-title" style={{backgroundImage: `url(${bg})`}}>
                    <div className="auto-container">
                        <h1>Our Services</h1>
                        <div className="text">

                        </div>
                    </div>
                </section>

                {/*Breadcrumb*/}
                <div className="theme-breadcrumb">
                    <div className="auto-container">
                        <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                            <li><a href={Paths.route_home}>Home</a></li>
                            <li className="active">Services</li>
                        </ol>
                    </div>
                </div>


                {/*Services Section*/}
                <section className="services-style-one no-padd-top">
                    <div className="auto-container">

                        <div className="row clearfix">

                            {/*Default Service Column*/}
                            {
                                our_services.map((service) =>
                                    <div className="default-service-column col-md-4 col-sm-6 col-xs-12" key={service.service_id}>
                                        <div className="inner-box">
                                            <div className="inner-most">
                                                <figure className="image-box">
                                                    <a href={`service/${service.service_id}`}>
                                                        <img src={service.images[0].image} alt="" style={{height: '350px'}}/>
                                                    </a>
                                                </figure>
                                                <div className="lower-part">
                                                    <div className="left-curve"></div>
                                                    <div className="right-curve"></div>

                                                    <div className="content">
                                                        <h3>{service.service_name}</h3>
                                                        <div className="more-link">
                                                            <a href={`service/${service.service_id}`} className="read-more">
                                                                Read more
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>

                {/*Default Intro Section*/}
                <section className="default-intro-section" style={{backgroundImage: `url(${img})`}}>
                    <div className="auto-container">
                        <div className="row clearfix">
                            <div className="col-md-10 col-sm-12 col-xs-12 pull-right">
                                {/*Content Box*/}
                                <div className="content-box">
                                    <div className="row clearfix">
                                        <div className="col-md-7 col-sm-12">
                                            <h3>Get your free consultant</h3>
                                            <div className="text">
                                                We are a company that offers design and build services for you from
                                                initial sketches to the final construction.
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 pull-right">
                                            <div className="text-right padd-top-20">
                                                <a href={Paths.route_contact} className="theme-btn btn-style-three">
                                                    Contact us <span className="icon icon-right flaticon-arrows-10"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </Container>
        </>
    )
}
