export class Utils {
    static post_data(data: any): FormData {
        const form = new FormData();
        Object.entries(data).forEach(([prop, val]) => {
            if (val !== null && val !== undefined) {
                if (val instanceof File) {
                    console.log(prop + " is a file")
                    form.append(prop, val)
                } else {
                    form.append(prop, val as string)
                }
            }
        })
        return form
    }

    static hide_preloader(div: HTMLDivElement) {
        const fadeEffect = setInterval(function () {
            if (!div.style.opacity) {
                div.style.opacity = "1";
            }

            const opacity = parseFloat(div.style.opacity)
            if (opacity > 0) {
                div.style.opacity = (opacity - 0.1).toString();
            } else {
                div.style.display = "none"
                clearInterval(fadeEffect);
            }
        }, 200)
    }
}
