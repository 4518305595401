import {Paths} from "../../App";
import logo from "../../../assets/images/logos/logo_white.png"

export default function AppBar() {
    return (
        <>
            <div className="header-upper">
                <div className="auto-container">
                    <div className="clearfix">

                        <div className="pull-left logo-outer">
                            <div className="logo">
                                <a href="/">
                                    <img src={logo} alt="" title="AGCL" style={{width: 'auto', height: '180px'}}/>
                                </a>
                            </div>
                        </div>

                        <div className="pull-right upper-right clearfix">
                            <div className="nav-outer">
                                {/*Main Menu*/}
                                <nav className="main-menu">
                                    <div className="navbar-header">
                                        {/*Toggle Button*/}
                                        <button type="button" className="navbar-toggle" data-toggle="collapse"
                                                data-target=".navbar-collapse">
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                    </div>

                                    <div className="navbar-collapse collapse clearfix">
                                        <ul className="navigation clearfix">
                                            <li><a href={Paths.route_home}>Home</a></li>
                                            <li><a href={Paths.route_about}>About AGCL</a></li>
                                            <li><a href={Paths.route_products}>Products</a></li>
                                            <li><a href={Paths.route_services}>Services</a></li>
                                            <li className={'d-none'}><a href={Paths.route_blogs}>Blogs</a></li>
                                            <li><a href={Paths.route_contact}>Contact</a></li>
                                        </ul>
                                    </div>
                                </nav>
                                {/*Main Menu End*/}

                                <div className="btn-outer">
                                    <a href={Paths.route_contact} className="theme-btn inq-btn">
                                        <span className="txt">Contact Us</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
