import Container from "../../utils/nav/Container";
import bg from "../../../assets/images/background/bg-page-title.jpg"
import {Paths} from "../../App";

import thumb_1 from "../../../assets/images/resource/post-thumb-3.jpg"
import thumb_2 from "../../../assets/images/resource/post-thumb-4.jpg"
import thumb_3 from "../../../assets/images/resource/author-thumb-4.jpg"

import img_1 from "../../../assets/images/resource/blog-image-11.jpg"

export default function Blog() {
    return (
        <>
            <Container>
                {/*Page Title*/}
                <section className="page-title" style={{backgroundImage: `url(${bg})`}}>
                    <div className="auto-container">
                        <h1>News Details</h1>
                        <div className="text">
                            Claritas est etiam processus dynamicus, qui sequitur <br/>mutationem consuetudium lectorum.
                        </div>
                    </div>
                </section>

                {/*Breadcrumb*/}
                <div className="theme-breadcrumb">
                    <div className="auto-container">
                        <ol className="breadcrumb" style={{marginBottom: '5px'}}>
                            <li><a href={Paths.route_home}>Home</a></li>
                            <li><a href={Paths.route_blogs}>Latest News</a></li>
                            <li className="active">News Details</li>
                        </ol>
                    </div>
                </div>


                {/*Sidebar Page Container*/}
                <div className="sidebar-page-container">
                    <div className="auto-container">
                        <div className="row clearfix">

                            {/*Content Side*/}
                            <div className="content-side col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                <div className="blog-posts-container blog-details">

                                    {/*Default Blog Post */}
                                    <article className="default-blog-post">
                                        <div className="inner-box">
                                            <div className="inner-most">
                                                <figure className="image-box"><a href="#"><img src={img_1} alt=""/></a></figure>
                                                <div className="lower-part">
                                                    <div className="left-curve"></div>
                                                    <div className="right-curve"></div>
                                                    <div className="date-box">
                                                        <span className="day">15</span>
                                                        <br/>Jan
                                                    </div>

                                                    <div className="content">
                                                        <h3><a href="#">Blog title goes here</a></h3>
                                                        <div className="author-info"><a href="#">by farhan rizvi</a></div>
                                                        <div className="text">
                                                            <p>
                                                                Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
                                                                suscipit
                                                                laboriosam, nisi ut aliquid etx ea commodi consequatur? Quis autem vel eum
                                                                iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae
                                                                consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla
                                                                pariatur.
                                                            </p>
                                                            <p>
                                                                Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium
                                                                lectorum. Mirum est notare quam littera gothica, quam nunc putamus parum
                                                                claram, anteposuerit litterarum formas humanitatis per seacula quarta decima
                                                                et quinta decima. Eodem modo typi qui nunc nobis videntur parum clari, fiant
                                                                sollemnes in futurum.
                                                            </p>

                                                            <blockquote>
                                                                Typi non habent claritatem insitam; est usus legentis in iis qui
                                                                facit eorum claritatem. Investigationes demonstraverunt lectores legere me
                                                                lius quod ii legunt saepius.
                                                            </blockquote>

                                                            <p>
                                                                Aminima veniam, quis nostrum exercitationem ullam corporis suscipit
                                                                laboriosam, nisi ut aliquid etx ea commodi consequatur? Quis autem vel eum
                                                                iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae
                                                                consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla
                                                                pariatur.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*Post Info*/}
                                                <ul className="post-info">
                                                    <li>
                                                        <span className="icon flaticon-square-1"></span>
                                                        <strong>Category:</strong> <a href="#">architecture</a></li>
                                                    <li>
                                                        <span className="icon flaticon-business-7"></span>
                                                        <strong>tags: </strong> <a href="#">design</a>, <a href="#">new</a></li>
                                                </ul>

                                            </div>
                                        </div>
                                    </article>

                                    {/*Comments Container*/}
                                    <div className="comments-container">
                                        <div className="sidebar-title">
                                            <h3>Comments on this post</h3>
                                        </div>

                                        <div className="inner-box">
                                            {/*Commment Box*/}
                                            <div className="comment-box">
                                                <div className="inner">
                                                    <figure className="image"><img src={thumb_3} alt=""/></figure>
                                                    <div className="content">
                                                        <h3 className="author-title">Bushra Ahsani</h3>
                                                        <div className="time">21st feb, 2016</div>
                                                        <div className="text">
                                                            Typi non habent claritatem insitam; est usus legentis in iis
                                                            qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me
                                                            lius quod ii legunt saepius.o
                                                        </div>
                                                        <a href="#" className="reply-btn">Reply</a>
                                                    </div>
                                                </div>
                                            </div>

                                            {/*Comment Box*/}
                                            <div className="comment-box">
                                                <div className="inner">
                                                    <figure className="image"><img src={thumb_3} alt=""/></figure>
                                                    <div className="content">
                                                        <h3 className="author-title">Bushra Ahsani</h3>
                                                        <div className="time">21st feb, 2016</div>
                                                        <div className="text">Typi non habent claritatem insitam; est usus legentis in iis
                                                            qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me
                                                            lius quod ii legunt saepius.o
                                                        </div>
                                                        <a href="#" className="reply-btn">Reply</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/*Comment Form*/}
                                    <div className="comments-form-outer">
                                        <div className="sidebar-title">
                                            <h3>leave a comment here</h3>
                                        </div>

                                        <div className="inner-box">
                                            <form method="post" action="https://html.themexriver.com/constructor-one/index.html">
                                                <div className="row clearfix">

                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <input type="text" name="name" value="" placeholder="YOUR NAME" required/>
                                                    </div>

                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <input type="email" name="email" value="" placeholder="EMAIL ADDRESS" required/>
                                                    </div>

                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <textarea name="message" placeholder="COMMENT HERE" required></textarea>
                                                    </div>

                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <button type="button" className="theme-btn btn-style-two">Submit Now <span
                                                            className="icon icon-right flaticon-arrows-10"></span></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*Sidebar*/}
                            <div className="sidebar-outer col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                <aside className="sidebar">

                                    {/*Search Widget*/}
                                    <div className="sidebar-widget search-widget">
                                        <div className="widget-inner">
                                            <form method="post" action="https://html.themexriver.com/constructor-one/index.html">
                                                <div className="form-group">
                                                    <input type="search" name="search" value="" placeholder="Search News Here"/>
                                                    <button type="submit" className="theme-btn">
                                                        <span className="fa fa-search"></span>
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    {/*News Widget*/}
                                    <div className="sidebar-widget news-widget">
                                        <div className="sidebar-title"><h3>Recent News</h3></div>
                                        <div className="widget-inner">
                                            {/*News Item*/}
                                            <div className="news-item">
                                                <figure className="post-thumb"><a href="#"><img src={thumb_1} alt=""/></a></figure>
                                                <h4><a href="#">Duis autem vel eum iriure dolor</a></h4>
                                                <div className="time">21st feb, 2016</div>
                                            </div>

                                            {/*News Item*/}
                                            <div className="news-item">
                                                <figure className="post-thumb"><a href="#"><img src={thumb_2} alt=""/></a></figure>
                                                <h4><a href="#">Duis autem vel eum iriure dolor</a></h4>
                                                <div className="time">21st feb, 2016</div>
                                            </div>

                                            {/*News Item*/}
                                            <div className="news-item">
                                                <figure className="post-thumb"><a href="#"><img src={thumb_1} alt=""/></a></figure>
                                                <h4><a href="#">Duis autem vel eum iriure dolor</a></h4>
                                                <div className="time">21st feb, 2016</div>
                                            </div>

                                            {/*News Item*/}
                                            <div className="news-item">
                                                <figure className="post-thumb"><a href="#"><img src={thumb_2} alt=""/></a></figure>
                                                <h4><a href="#">Duis autem vel eum iriure dolor</a></h4>
                                                <div className="time">21st feb, 2016</div>
                                            </div>

                                        </div>
                                    </div>

                                    {/*Links Widget*/}
                                    <div className="sidebar-widget links-widget">
                                        <div className="sidebar-title"><h3>Category</h3></div>
                                        <div className="widget-inner">
                                            <ul>
                                                <li><a href="#"> Architect</a></li>
                                                <li><a href="#"> Building</a></li>
                                                <li><a href="#"> Travel</a></li>
                                                <li><a href="#"> Culture</a></li>
                                                <li><a href="#"> School</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}
